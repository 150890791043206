import React, { Component, useCallback } from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { Layout } from "antd";
import { isNil } from "ramda";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";

import { logout, updateUserPassword } from "../../actions/auth";
import { createItemRest } from "../../actions/companies";
import { errorBoundary } from "../../components/general/error-boundary";
import FontawesomeIcon from "../../components/general/fontawesome-icon";
import Logo from "../../components/general/logo";
import CreateForm from "../../components/manage-data/company/create-form";
import GeneralTermsModal from "../general-terms-modal";
import PrivacyModal from "../privacy-modal";
import {
    FormContainer,
    FormContent,
    FormHeader,
    ModalButton,
    ModalButtonsContainer,
    ModalText,
    StyledIcon,
    StyledModal,
    Title
} from "./styled";
import { useFeature } from "../../hooks/use-feature";
import ItemRegistryView from "../child-apps/item-registry";
import LayoutHeaderComponent from "../../components/manage-data/user/new-user-modal/layout-header-component";
import {
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList
} from "../../actions/ncs/write";
import { editUser } from "../../actions/company/users";
import { loadUser } from "../../actions/user";
import { getNotificationsList, getNotificationsStatus } from "../../actions/ncs/read";

export class CreateItem extends Component {
    constructor() {
        super();
        this.state = {
            isCompanyCreate: false,
            showWarningModal: false
        };
    }

    static propTypes = {
        auth: PropTypes.object,
        companies: PropTypes.object.isRequired,
        createItemRest: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const { companies } = this.props;

        if (prevProps.companies.createItem.status.started && companies.createItem.status.ended) {
            this.setState({ isCompanyCreate: true });
        }
    }

    handleCreateItem(companyData) {
        const { createItemRest } = this.props;
        if (!isNil(companyData)) {
            let dataToInsert;

            let address = {
                types: ["REGISTERED_OFFICE"],
                streetName: companyData.streetName,
                streetNumber: companyData.streetNumber,
                city: companyData.city,
                province: companyData.province,
                zipCode: companyData.zipCode,
                country: companyData.country
            };

            dataToInsert = {
                base: {
                    identifier: {
                        taxId: companyData.taxRegion !== "IT" ? companyData.vatNumber : companyData.taxId,
                        vatNumber: companyData.vatNumber,
                        taxRegion: companyData.taxRegion
                    },
                    details: {
                        classifier: companyData.classifier,
                        description: companyData.description,
                        addresses: [address]
                    }
                }
            };

            createItemRest(dataToInsert);
        }
    }

    render() {
        const { auth, companies, logout } = this.props;
        const { isCompanyCreate, showWarningModal } = this.state;

        return isCompanyCreate ? (
            <Redirect to="/createItem/success" push />
        ) : auth && auth.loginAuth ? (
            <Layout className="Root">
                <GeneralTermsModal />
                <PrivacyModal />
                <Layout.Content className="Root-content">
                    <div className="Root-logo">
                        <Logo className="Root-logo-img" />
                    </div>
                    <StyledModal
                        footer={false}
                        onCancel={() =>
                            this.setState({
                                showWarningModal: false
                            })
                        }
                        visible={showWarningModal}
                    >
                        <StyledIcon>
                            <FontawesomeIcon color="#fdb927" name="exclamation-triangle" size="32px" solid={true} />
                        </StyledIcon>
                        <ModalText>
                            <FormattedMessage id="c-create-item.modal.warning" />
                        </ModalText>
                        <ModalButtonsContainer>
                            <ModalButton
                                onClick={() =>
                                    this.setState({
                                        showWarningModal: false
                                    })
                                }
                                variant="secondary"
                            >
                                <FormattedHTMLMessage id="general.annulla" />
                            </ModalButton>
                            <Button onClick={() => logout()}>
                                <FormattedHTMLMessage id="general.continue" />
                            </Button>
                        </ModalButtonsContainer>
                    </StyledModal>
                    <FormContainer>
                        <FormHeader>
                            <Title>
                                <FormattedMessage id="c-company-create-modal.create-company" />
                            </Title>
                        </FormHeader>
                        <FormContent>
                            <FormattedHTMLMessage id="root.layout.inserisci-azienda" />
                            <CreateForm
                                cancelButtonText={<FormattedMessage id="general.annulla" />}
                                errorMsg={
                                    companies.createItem.status.error && companies.createItem.status.errorInfo.message
                                }
                                initialValues={{
                                    country: "IT",
                                    taxRegion: "IT",
                                    token: auth.loginAuth.securityToken
                                }}
                                handleCancel={() => this.setState({ showWarningModal: true })}
                                onSubmit={companyData => this.handleCreateItem(companyData)}
                            />
                        </FormContent>
                    </FormContainer>
                </Layout.Content>
            </Layout>
        ) : (
            <Redirect to="/login" push />
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        companies: state.companies
    };
}

const composedHoc = compose(connect(mapStateToProps, { createItemRest, logout }), errorBoundary);

const CreateItemComponent = composedHoc(CreateItem);

const CreateItemView = ({
    auth,
    notificationsExist,
    notificationsList,
    notificationsListStatus,
    user,
    updateUserPswStatus,
    userEditStatus,
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList,
    logout,
    editUser,
    loadUser,
    updateUserPassword,
    getNotificationsList,
    getNotificationsStatus
}) => {
    const NEW_ITEM_REGISTRY = useFeature("NEW_ITEM_REGISTRY");

    const getNcsNotificationsList = useCallback(() => {
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }, [getNotificationsList, user.user]);

    const getNcsNotificationsExist = useCallback(() => {
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }, [getNotificationsStatus, user.user]);

    if (NEW_ITEM_REGISTRY) {
        return (
            <Layout>
                <LayoutHeaderComponent
                    auth={auth}
                    user={user}
                    logout={logout}
                    display={true}
                    editUser={editUser}
                    loadUser={loadUser}
                    notificationsExist={notificationsExist}
                    notificationsList={notificationsList}
                    notificationsListStatus={notificationsListStatus}
                    deleteSingleNotification={notificationId =>
                        deleteSingleNotification(user.user.profile.ncsId, notificationId)
                    }
                    deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                    getNotifications={getNcsNotificationsList}
                    getNotificationsExist={getNcsNotificationsExist}
                    updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                    updateSingleNotification={(notificationId, request) =>
                        updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                    }
                    updateUserPsw={updateUserPassword}
                    updateUserPswStatus={updateUserPswStatus}
                    userEditStatus={userEditStatus}
                />
                <div style={{ position: "relative", height: "calc(100vh - 64px)" }}>
                    <ItemRegistryView />
                </div>
            </Layout>
        );
    }

    return <CreateItemComponent />;
};

export default compose(
    connect(
        state => ({
            auth: state.auth,
            companies: state.companies,
            createItemStatus: state.companies.createItem.status,
            notificationsExist: state.ncs.notifications.exist,
            notificationsList: state.ncs.notifications.data,
            notificationsListStatus: state.ncs.notifications.status,
            user: state.user,
            updateUserPswStatus: state.password.update.status || {},
            userEditStatus: state.manageUser.editUser.status || {}
        }),
        {
            deleteSingleNotification,
            deleteNotificationsList,
            updateSingleNotification,
            updateNotificationsList,
            logout,
            editUser,
            loadUser,
            updateUserPassword,
            getNotificationsList,
            getNotificationsStatus
        }
    )
)(CreateItemView);
