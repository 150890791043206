import React, { useEffect } from "react";
import useElevioControls from "../../../hooks/use-elevio-controls";
import PortaleChildApp from "../../portale-child-app";
import { useHistory } from "react-router-dom";

const ItemRegistryView = () => {
    const { hideWidget } = useElevioControls();
    const history = useHistory();

    useEffect(() => {
        hideWidget();
    }, [hideWidget]);

    useEffect(() => {
        const listener = message => {
            if (message.data && typeof message.data === "string") {
                const parsedMessage = JSON.parse(message.data);

                if (parsedMessage.type === "NAVIGATE" && typeof parsedMessage.to === "string") {
                    history.push(parsedMessage.to);
                }
            }
        };

        window.addEventListener("message", listener);

        return () => {
            window.removeEventListener("message", listener);
        };
    }, [history]);

    return <PortaleChildApp appId="rnd:core:item-registry:app" serviceId="rnd:core:item-registry:app" />;
};

export default ItemRegistryView;
